import state from './moduleOfferProductState.js'
import mutations from './moduleOfferProductMutations.js'
import actions from './moduleOfferProductActions.js'
import getters from './moduleOfferProductGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
