<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vx-card title="الفلترة" class="mb-2" title-color="primary">
      <div class="vx-row align-center">
        <div class="vx-col md:w-1/2 ">
          <label class="vs-input--label mt-5">بحث عن المنتج</label>
          <vs-input placeholder="بحث ب sku" type="text" class="md:w-3/4" name="note" v-model="searchQuery" />
        </div>

        <div class="vx-col md:w-1/2">
          <label class="vs-input--label mt-5">العلامة التحارية</label>
          <v-select label="name" data-vv-as="العلامة التجارية" :reduce="(item) => item.id" v-model="brand_id"
            :options="brands" dir="rtl" name="brand" class="w-full"></v-select>
        </div>
      </div>
      <div class="vx-row justify-end">
        <vs-button class="mr-3" color="#7367F0" type="filled" @click="search()">
          بحث
        </vs-button>
        <vs-button class="mr-3" color="warning" type="border" @click="reset()">
          إعادة تعيين
        </vs-button>
      </div>
    </vx-card>
    <div class="demo-alignment">
      <vs-popup class="holamundo" title="تعديل ترتيب المنتج" :active.sync="popupActiveEdit">
        <vx-card title="حدث خطأ" class="mb-2" title-color="danger" v-if="Object.keys(errors_values).length">
          <div v-for="(key, index) in Object.keys(errors_values)" :key="index">
            <span v-for="(error, index) in errors_values[key]" :key="index" class="text-danger">
              {{ error }}
            </span>
          </div>
        </vx-card>
        <div class="flex flex-col justify-items-center">
          <div class="mb-5">
            <label class="vs-input--label">الترتيب </label>
            <vs-input placeholder="الترتيب" type="text" class="w-full" name="note" v-model="offerProduct.level" />
          </div>
          <div class="mb-5">
            <label class="vs-input--label">تاريخ نهاية العرض </label>
            <vs-input type="date" class="w-full" v-model="offerProduct.end_date" name="startDate"
              data-vv-as="حقل تاريخ النهاية" v-validate="'required'" />
          </div>
        </div>

        <vs-button @click="update()" color="success" type="filled" icon-pack="feather" icon="icon-check">
          تعديل</vs-button>
      </vs-popup>
    </div>
    <div class="demo-alignment">
      <vs-popup class="holamundo" title="إضافة عروض منتجات" :active.sync="popupActive">
        <vx-card title="حدث خطأ" class="mb-2" title-color="danger" v-if="Object.keys(errors_values).length">
          <div v-for="(key, index) in Object.keys(errors_values)" :key="index">
            <span v-for="(error, index) in errors_values[key]" :key="index" class="text-danger">
              {{ error }}
            </span>
          </div>
        </vx-card>
        <div class="flex flex-col justify-items-center">
          <div class="mb-5">
            <label class="vs-input--label">المنتج </label>
            <v-select name="sku" dir="rtl" class="w-full" data-vv-as="sku" data-vv-scope="level-items" label="sku"
              :options="products" :tabindex="4" :filterable="true" :reduce="(item) => item.id"
              v-model="offerProduct.product_id"></v-select>
          </div>
          <div class="mb-5">
            <label class="vs-input--label">الترتيب </label>
            <vs-input placeholder="الترتيب" type="text" class="w-full" name="note" v-model="offerProduct.level" />
          </div>
          <div class="mb-5">
            <label class="vs-input--label">تاريخ نهاية العرض </label>
            <vs-input type="date" class="w-full" v-model="offerProduct.end_date" name="startDate"
              data-vv-as="حقل تاريخ النهاية" v-validate="'required'" />
          </div>
        </div>

        <vs-button @click="submit()" color="success" type="filled" icon-pack="feather" icon="icon-check">
          انشاء</vs-button>
      </vs-popup>
    </div>

    <vs-table ref="table" :data="offerProducts">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <!-- ADD NEW -->
          <vs-button @click="popupActive = true"
            class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base border border-solid border-primary">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            إضافة offer
          </vs-button>
        </div>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="price">السعر</vs-th>
        <vs-th sort-key="price">سعر التخفيض</vs-th>
        <vs-th sort-key="price">الترتيب</vs-th>
        <vs-th sort-key="price">تاريخ النهاية</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="product-img-container flex justify-center items-center" v-if="tr.product.media.length">
              <img :src="tr.product.media[0].full_path" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">
                {{ tr.product.name }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.product.sku }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.product.price }}LYD</p>
            </vs-td>
            <vs-td>
              <p class="product-price">{{ tr.product.new_price }}LYD</p>
            </vs-td>
            <vs-td>
              <p class="product-price">{{ tr.level }}</p>
            </vs-td>
            <vs-td>
              <p class="product-price">{{ tr.end_date }}</p>
            </vs-td>

            <vs-td>
              <a @click="editData(tr)">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" />
              </a>
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                @click.stop="confirm(tr.id)" />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import moduleOfferProduct from "@/store/offerProduct/moduleOfferProduct.js";
import moduleBrand from "@/store/brands/moduleBrand.js";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      offerProducts: [],
      offerProduct: { level: "", product_id: "",end_date:'' },
      addNewDataSidebar: false,
      sidebarData: {},
      offer_product_id: "",
      popupActive: false,
      popupActiveEdit: false,
      searchQuery: "",
      brand_id: "",
      params: {},
      errors_values: [],
    };
  },
  computed: {
    brands() {
      return this.$store.state.brand.brands;
    },
    products() {
      return this.$store.state.products;
    },
  },
  methods: {
    ...mapActions("offerProduct", [
      "fetchOfferProduct",
      "createNewOfferProduct",
      'updateOfferProduct'
    ]),
    search() {
      this.params["exact[product.brand_id]"] = this.brand_id;
      this.params["exact[product.sku]"] = this.searchQuery;
      this.getData(1);
    },
    getData(page) {
      this.params["page"] = page;
      this.fetchOfferProduct(this.params)
        .then((response) => {
          this.offerProducts = response.data.products;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    editData(data) {
      this.offerProduct = data;
      this.popupActiveEdit = true;
    },
    confirm(id) {
      this.offer_product_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من مسح هذا المنتج؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.$store
        .dispatch("offerProduct/deleteOfferProduct", this.offer_product_id)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم المسح بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    submit() {
      this.createNewOfferProduct(this.offerProduct)
        .then((response) => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم بنجاح",
            color: "success",
          });
          this.popupActive = false;
        })
        .catch((err) => {
          this.errors_values = err.response.data.errors;
          this.$vs.notify({
            title: "حدث خطأ",
            text: err,
            color: "danger",
          });
        });
    },
    update() {
      this.updateOfferProduct(this.offerProduct)
        .then((response) => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم بنجاح",
            color: "success",
          });
          this.popupActiveEdit = false;
        })
        .catch((err) => {
          this.errors_values = err.response.data.errors;
          this.$vs.notify({
            title: "حدث خطأ",
            text: err,
            color: "danger",
          });
        });
    },
  },
  mounted() {
    this.$vs.loading();
  },
  created() {
    if (!moduleBrand.isRegistered) {
      this.$store.registerModule("brand", moduleBrand);
      moduleBrand.isRegistered = true;
    }
    if (!moduleOfferProduct.isRegistered) {
      this.$store.registerModule("offerProduct", moduleOfferProduct);
      moduleOfferProduct.isRegistered = true;
    }
    this.$store.dispatch("brand/fetchBrands");
    this.$store.dispatch("getAllProducts");
    this.getData();
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      >span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.product-img-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
