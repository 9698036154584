import axios from "@/axios.js"

export default {
    fetchOfferProduct({commit},params){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/offer-products`,{params:params})
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    createNewOfferProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/offer-products/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    updateOfferProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/offer-products/update/${payload.id}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    deleteOfferProduct({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/offer-products/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
}
